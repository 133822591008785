body{
  background-color: #F5FFFF;
  font-family: sans-serif;
}

#top-nav{
  background-color: #03989E;
}

#trending{
  background-color: #03989E;
}

.menu{
  background-color: #E4DEDE;
}

.bar{
  margin: 0px 0px 0px 0px;
}

.required:after {
  content: " *";
  color: red;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.bg-facebook {
  background-color: #3b5998;
}
.bg-twitter {
  background-color: #1da1f2;
}
.bg-google {
  background-color: #ea4335;
}
.bg-instagram {
  background-color: #e1306c;
}

.bs-cover {
  background-size: cover;
}

.r-0 {
  right: 0;
}
.mw-180 {
  max-width: 180px;
}
.mw-140 {
  max-width: 140px;
}
.i-va{
  vertical-align: -0.125em;
}

/* Explore Fashion */


ul{
  margin:0;
  padding:0;
  list-style:none;
  }
  .padding-lg {
  display: block;
  padding-top: 60px;
  padding-bottom: 60px;
  }
  
  .our-webcoderskull .cnt-block:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    border: 0;
  }
  
  .our-webcoderskull .cnt-block{ 
   float:left; 
   width:100%; 
   background:#fff; 
   padding:30px 20px; 
   text-align:center; 
   margin: 0 0 28px;
  }
  .our-webcoderskull .cnt-block figure{
   width:148px; 
   height:148px; 
   border-radius:100%; 
   display:inline-block;
   margin-bottom: 15px;
  }
  .our-webcoderskull .cnt-block img{ 
   width:148px; 
   height:148px; 
   border-radius:100%; 
  }
  .cnt-block h3{
    color:black;
  }

  .lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: rgb(40, 17, 247);
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  
  .appProduct{
    max-width: 1200px;
    width: 100%;
    /* margin: 100px auto; */
    /* box-shadow: 0 0 5px #ccc; */
  }
  .details{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 0;
  }
  .details .big-img{
    max-width: 500px;
    min-width: 290px;
    height: 53vh;
    overflow: hidden;
    margin: 0px;
  }
  .big-img img{
    width: 100%;
    height: 100%;
    max-height: 300px;
    display: block;
    object-fit: cover;
  }
  
  .details .box{
    max-width: 500px;
    min-width: 290px;
    margin-top: 30px;
  }
  .box .row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .box .row h2{
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .box .row span{
    color: crimson;
  }
  .box .colors button{
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
  }
  .box p{
    line-height: 1.5;
    margin: 15px 0;
  }
  .thumb{
    width: 100%;
    height: 100px;
    /* display: flex; */
    cursor: pointer;
    margin: 0 0;
  }
  .thumb img{
    height: 12vh;
    /* width: 10vw; */
    display: block;
    object-fit: cover;
    border: 1px solid #ddd;
    margin: 10px 8px;
    opacity: 0.7;
    border-radius: 5px;
  }
  .thumb img.active{
    opacity: 1;
    border: 1px solid lightseagreen;
  }
  .box .cart{
    background: #333;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px 25px;
    margin-top: 15px;
  }
  
  @media (max-width: 500px){
    .thumb{
      height: 50px;
    }
    .thumb img{
      width: 50px;
    }
  }
  .img-wrapper {
    overflow: hidden;
  }
  
  img.hover-zoom {
    transition: all 0.3s ease 0s;
    width: 100%;
  }
  img.hover-zoom:hover {
    transform: scale(1.05);
  }
  .main-content{
    min-height: 100vh;
  }
  .suggestions ul{
    position:absolute;
    z-index: 1;
    width: auto;
    min-width: 700px;
    max-height: 20px;
  }
  .suggestions ul li{
    font-size: 1rem !important;
    padding: 4px 10px;
    height: 5vh;
    text-decoration: none;
  }
  .suggestions ul li:hover{
    background-color: #E4DEDE;
  }